<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
        <v-chip-group
          @change="select()"
          mandatory
          v-model="searchParams.status"
        >
          <v-chip color="primary" filter value="1"> Ativos </v-chip>
          <v-chip outlined filter value="0">Inativos</v-chip>
        </v-chip-group>
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          v-if="$acl.can('brandCreateEdit')"
          color="primary"
          @click="handleStoreBrand()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="brands.data"
          :items-per-page="30"
          hide-default-footer
          @click:row="handleStoreBrand($event.id)"
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="brands.last_page"
          @input="select($event)"
          :total-visible="9"
        />
      </v-col>
    </v-row>
    <store-brands-form ref="StoreBrandsForm" @store="select()" />
  </div>
</template>

<script>
import StoreBrandsForm from "@/components/brands/forms/StoreBrandsForm.vue";

export default {
  components: { StoreBrandsForm },

  data() {
    return {
      headers: [
        { sortable: false, text: "Código", value: "code" },
        { sortable: false, text: "Nome", value: "name", width: "80%" },
        { sortable: false, text: "Status", value: "status", align: "end" },
      ],
      loading: false,
      searchParams: {
        page: 1,
        status: 1,
      },
      brands: {},
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.loading = true;
      this.searchParams.page = page;
      this.$http.index("product/brand", this.searchParams).then((response) => {
        this.brands = response.brands;
        this.loading = false;
      });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleStoreBrand(id = null) {
      if (this.$acl.can("brandCreateEdit")) {
        this.$refs.StoreBrandsForm.open(id);
      }
    },
  },
};
</script>

<style>
</style>